<template>
  <div class="textarea-container">
    <label class="lbl" v-if="this.label" :for="this.id">{{ this.label }}</label>
    <div
      ref="editorContent"
      :contenteditable="!this.disabledTextarea"
      :class="[
        'fake-textarea',
        { 'fake-textarea--error': this.charactersLeft < 0 },
      ]"
      :label="this.label"
      @input="handleInput($event)"
      @paste="cleanUpOnPaste($event)"
    ></div>
    <input
      class="textarea-container__input"
      type="hidden"
      v-model="content"
      :name="this.name"
      :id="this.id"
    />
    <div class="textarea-container__edit-buttons">
      <button
        @click.prevent="editText('italic')"
        class="textarea-container__edits"
      >
        /
      </button>
      <button
        @click.prevent="editText('bold')"
        class="textarea-container__edits textarea-container__edits--bold"
      >
        B
      </button>
      <button
        @click.prevent="editText('underline')"
        class="textarea-container__edits textarea-container__edits--underline"
      >
        U
      </button>
    </div>
    <span class="textarea-container__characters"
      >Pozostało znaków: {{ charactersLeft }}</span
    >
  </div>
</template>
<script>
import xss from "xss";

const myxss = new xss.FilterXSS({
  css: {
    whiteList: {},
  },
  whiteList: {
    style: [],
    p: [],
    div: [],
    b: [],
    u: [],
    i: [],
    br: [],
  },
  stripIgnoreTag: true,
});

export default {
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    defaultValue: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    maxCharacters: {
      type: Number,
      default: 2000,
    },
    usedCharacters: {
      type: Number,
    },
    otherTextareasCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      content: this.value,
      contentLength: 0,
      disabledTextarea: this.disabled,
    };
  },
  computed: {
    charactersLeft() {
      let charsLeft = this.maxCharacters;
      if (typeof this.usedCharacters === "number") {
        charsLeft = this.usedCharacters;
      } else {
        charsLeft = this.maxCharacters - this.contentLength;
      }
      this.$emit("content-length", {
        length: this.contentLength,
        name: this.name,
      });
      return charsLeft;
    },
  },
  methods: {
    cleanUpOnPaste(e) {
      e.preventDefault();
      const editorContent = this.$refs.editorContent;
      const pastedText = (e.clipboardData || window.clipboardData).getData(
        "text"
      );

      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      const textNode = document.createTextNode(pastedText);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      this.content = editorContent.innerHTML;
      this.$emit("input", this.content);

      const countLineBreaks =
        (editorContent.innerText.match(/\n/g) || []).length * 20;
      this.contentLength = editorContent.textContent.length + countLineBreaks;
    },
    handleInput(e) {
      const xssProcessed = myxss.process(e.target.innerHTML);
      this.content = xssProcessed;
      const countLineBreaks =
        (e.target.innerText.match(/\n/g) || []).length * 20;
      this.contentLength = e.target.textContent.length + countLineBreaks;
      this.$emit("input", this.content);
    },
    editText(edit) {
      document.execCommand(edit);
      this.handleInput();
    },
    autoSave() {
      this.$emit("auto-save");
    },
  },
  watch: {
    disabled: function () {
      this.disabledTextarea = this.disabled;
    },
  },
  mounted() {
    this.$nextTick(function () {
      const editorContent = this.$refs.editorContent;
      editorContent.innerHTML = this.value;
      const countLineBreaks =
        (editorContent.innerText.match(/\n/g) || []).length * 20;
      this.contentLength = editorContent.textContent.length + countLineBreaks;
      this.content = this.value;
    });
    this.$emit("content-length", {
      length: this.contentLength,
      name: this.name,
    });
  },
};
</script>

<style lang="scss" scoped>
.textarea-container {
  position: relative;
  margin-bottom: 40px;
  margin-top: 0px;

  &__characters {
    float: right;
    color: $dark-gray-color;
  }

  &__input {
    visibility: hidden;
  }

  &__edits {
    cursor: pointer;
    font-size: 12px;

    &--bold {
      font-weight: bold;
    }

    &--underline {
      text-decoration: underline;
    }
  }

  &__edit-buttons {
    float: left;
  }
}
.fake-textarea {
  border: 0.5px solid $light-gray-color;
  width: 100%;
  min-height: 150px;
  outline: none;
  text-align: left;
  padding: 5px;
  margin-bottom: 5px;
  word-break: break-all;

  &--error {
    border: 2px solid red;
  }

  div {
    width: 100% !important;
  }
}
.lbl {
  margin-left: 0;
  margin-bottom: 5px;
  font-size: 12px;
}
</style>
